  /* fuck all of this */


/* Customize the label (the container) */
.tileContainer {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

  /* Hide the browser's default checkbox */
  /* .tileContainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  } */
  
  /* Create a custom checkbox */
  .checkmark {
    /* position: absolute;
    top: 0;
    left: 0; */
    height: 25px;
    width: 25px;
    background-color: #eee;
  }
  
  /* On mouse-over, add a grey background color
  .tileContainer:hover input ~ .checkmark {
    background-color: #ccc;
  }
  
  /* When the checkbox is checked, add a blue background */
  .tileContainer input:checked ~ .checkmark {
    background-color: #C152F5;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  /* .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  } */
  
  /* Show the checkmark when checked */
  /* .tileContainer input:checked ~ .checkmark:after {
    display: block;
  } */
  
  /* Style the checkmark/indicator */
  /* .tileContainer .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }  */

.gtIssued {
  border: 1px solid red;
  border-radius: 20px;
}

.imgContainer {
  position: relative;
  text-align: right;
}

.topRight {
  position: absolute;
  top: -10px;
  right: 16px;
}