.factory-img-container img {
    width: 75%;
    /*height: 480px;*/
    /*border-radius: 30px 0px 0px 30px;*/
    border-radius: 10px;
    padding-bottom: 10px;

  }

  @media only screen and (max-width: 1280px) {
    .factory-img-container img {
      width: inherit;
      height: 100%
    }
  }

  @media only screen and (max-width: 640px) {
    .factory-img-container img {
      width: 100%;
      height: 100%
    }
  }