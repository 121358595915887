@import url(https://fonts.googleapis.com/css2?family=Comfortaa:wght@300&display=swap);
@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(/static/media/Poppins-Regular.8081832f.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'PoppinsEB';
  src: local('PoppinsEB'), url(/static/media/Poppins-ExtraBold.4ed78cd5.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Raleway';
  src: local('Raleway'), url(/static/media/Raleway-VariableFont_wght.3fefe681.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
/*
img {
  image-rendering: -webkit-optimize-contrast;
}
*/
body {
  font-family: Poppins;
  font-weight: normal;
  font-size: 18px;
  line-height: 189%;
  background-color: black;
  color: white;
}

body h1 {
  font-weight: bold !important;
  font-size: 36px;
  line-height: 54px;
}

main {
  min-height: 80vh;
  width: 95%;
  max-width: 1600px;
  margin: auto;
}

.clickHereText {
  text-decoration: underline;
  transition: color 0.15s ease-in-out;
  color: #FFF;
  cursor: pointer;
}
.clickHereText:hover {
  color: #C152F5;
}

.teddyButton {
  color: #000000;
  border-color: #C152F5;
  background-color: #C152F5;
  border-radius: 12.6858px;
  text-align: center;
  letter-spacing: 0.025em;
  transition: color 0.15s ease-in-out;
}
.teddyButton:hover {
  color: #fff;
  background-color: #C152F5;
  border-color: #C152F5;
}
.teddyButton:disabled {
  color: #FFF;
  background-color: #512366;
  border-color: #512366;
}



.enterButton {
  color: #000000;
  border-color: #C152F5;
  background-color: #C152F5;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 12px;
  padding-bottom: 12px;
  width: 415px;
  border-radius: 12.6858px;

  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 25.3717px;
  line-height: 38px;
}

.homeMintButton {
  color: #000000;
  border-color: #C152F5;
  background-color: #C152F5;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 12px;
  padding-bottom: 12px;
  width: 100%;
  max-width: 388px;
  height: 64px;
  border-radius: 12.6858px;

  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  
  /* identical to box height */
  text-align: center;
  letter-spacing: 0.025em;
}


.mintButton {
  color: #000000;
  border-color: #C152F5;
  background-color: #C152F5;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 12px;
  padding-bottom: 12px;
  width: 388px;
  height: 64px;
  border-radius: 12.6858px;

  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  
  /* identical to box height */
  text-align: center;
  letter-spacing: 0.025em;
}

.navMintButton {
  color: #000000;
  border-color: #C152F5;
  background-color: #C152F5;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 12px;
  padding-bottom: 12px;
  width: 167px;
  height: 48px;
  border-radius: 12.6858px;

  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  
  /* identical to box height */
  text-align: center;
  letter-spacing: 0.025em;
}

.rarityButton {
  color: #000000;
  border-color: #C152F5;
  background-color: #C152F5;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 12px;
  padding-bottom: 12px;
  width:100%;
  max-width: 339px;
  height: 56px;
  border-radius: 12.6858px;

  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  
  /* identical to box height */
  text-align: center;
  letter-spacing: 0.025em;
  transition: color 0.15s ease-in-out;
}

.rarityButton:hover {
  color: #fff;
  background-color: #C152F5;
  border-color: #C152F5;
}
.rarityButton:disabled {
  color: #FFF;
  background-color: #512366;
  border-color: #512366;
}


.welcomeText {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 56px;
  line-height: 115.5%;
}

.storyText {
  font-family: Raleway;
  
  font-style: normal;
  font-weight: normal;
  /*
  font-size: 24px;
  line-height: 28px;
  */
}

.poppins{
  font-family: Poppins;
}

.homeTitle {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 72px;
}

.homeStory {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 189%;
}

.copywright {
  font-family: Poppins;
  font-style: normal;
  font-size: 16px;
}

.termsLink {
  color: #C152F5;
  font-size: 16px;
}




/* unvisited link */
a:link {
  color: #C152F5;
  transition: color 0.15s ease-in-out;
}

/* visited link */
a:visited {
  color: #5f287a;
}

/* mouse over link */
a:hover {
  color: #ae00ff;
}

/* selected link */
a:active {
  color: #ff34b1;
}




.purpleText {
  color: #C152F5;
}

.footnote {
  font-style: italic;
  height: auto;
  margin: 0;
  font-size: 16px;
}

.mintRow {
  min-height: 333px;
  background-color: #2D2C2C;
  background-image:
      radial-gradient(
          circle at right,
          #000000,
          #2D2C2C 20%,
          transparent 40%

      ),
      radial-gradient(
          circle at left,
          #000000,
          #2D2C2C 20%,
          transparent 40%
      );
}

.idk1{
  position: absolute;
  width: 152.33px;
  height: 9.5px;
  left: 1679.64px;
  top: 1982.14px;

  border: 4px solid #AFAFAF;
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.mintRow2 {
  min-height: 333px;
  background-color: #2D2C2C;
  background-image:
      radial-gradient(
          circle at right,
          #000000,
          #2D2C2C 20%,
          transparent 40%

      ),
      radial-gradient(
          circle at left,
          #000000,
          #2D2C2C 20%,
          transparent 40%
      );
}

.roadmapEllipse {
  position: relative;
  text-align: center;
  color: white;
  width: 100px;
}

.roadmapNumber {
  position: absolute;
  top: 48%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.roadmapItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: auto;
}

.teamTeddys {
  display: flex;
  justify-content: flex-end;
  margin: auto;
}

.teddyNavLink {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  color: #FFFFFF !important;
  transition: color 0.15s ease-in-out !important;
}
.teddyNavLink:hover{
  color: #C152F5 !important;
}

.teddyNavBar li {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.fairTitle {
  font-family: PoppinsEB;
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  line-height: 60px;
  
  /* identical to box height */
  
  color: #EAEAEA;
}

.priceTitle {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 36px;
}

.footerDiv {
  position: relative;
  z-index : 1;
  width: 100%
}

.footerDiv:before {
  content : "";
  position: absolute;
  left    : 5%;
  top     : 0;
  height  : 1px;
  width   : 90%;  /* or 100px */
  border-top:1px solid #FFFFFF;
}

.emailForm {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-end;
  text-align: left;
  padding-bottom: 30px;
  padding-left: 30px;
}

.emailBtn {
  display:block;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: #D3D3D3;
  border: 1px solid black;
  margin-left: 10px;
}

.emailBox {
  border: 1px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 117px;
  height: 45px;
  color: #fff;
  background-color: black;
}
.emailBox:focus {
  color: #fff;
  background-color: black;
  box-shadow: inset 0 1px 1px #C152F5, 0 0 8px #C152F5;
}

.addressBox {
  border: 1px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 10px;
  height: 45px;
  width: 400px;
  /*color: #fff;
  background-color: black;*/
}
.addressBox:focus {
  /*color: #fff;
  background-color: black;*/
  box-shadow: inset 0 1px 1px #C152F5, 0 0 8px #C152F5;
}


.lookupBox {
  border: 1px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 117px;
  height: 45px;
  width: 100px;
  color: #fff;
  background-color: black;
}
.lookupBox:focus {
  color: #fff;
  background-color: black;
  border: 1px solid #C152F5;
}

.lookupBtn {
  display:block;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: #C152F5;
  border: 1px solid black;
  margin-left: 10px;
  transition: color 0.15s ease-in-out;
}
.lookupBtn:hover {
  color: white;
}


.keplrButton {
  color: #000000;
  border-color: #C152F5;
  background-color: #C152F5;
  border-radius: 12.6858px;
  text-align: center;
  letter-spacing: 0.025em;
  transition: color 0.15s ease-in-out;
  font-size: 20px;
  padding: 10px 20px;
}
.keplrButton:hover {
  color: #fff;
  background-color: #C152F5;
  border-color: #C152F5;
}
.keplrButton:disabled {
  color: #FFF;
  background-color: #512366;
  border-color: #512366;
}

.modalButton {
  color: #FFF;
  border-color: #9d3fc900;
  background-color: #C152F5;
  border-radius: 0.25rem;
  line-height: 1.5;
  text-align: center;
  letter-spacing: 0.025em;
  transition: color 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out;
  font-size: 1rem;
  padding: 0.375rem 0.75rem;
}
.modalButton:hover {
  color: #000000;
  background-color: #9d3fc9;
  border-color: #c152f500;
}
.modalButton:disabled {
  color: #FFF;
  background-color: #512366;
  border-color: #51236600;
}




.whiteFont {
  color: white !important;
}

.backLink {
  color: #FFFFFF;
  transition: color 0.15s ease-in-out;
}
.backLink:hover {
  color: #C152F5;
}

.alterLink {
  transition: filter 0.2s ease-in-out;
  width: 40px !important;
  height: 40px !important;
  margin-top: 0px !important;
}
.alterLink:hover {
  /*box-shadow: 0px 0px 50px 20px #C152F5;*/
  filter: drop-shadow(0px 0px 15px #C152F5);
}

@keyframes c-inline-spinner-kf {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.c-inline-spinner,
.c-inline-spinner:before {
  display: inline-block;
  width: 20px;
  height: 20px;
  transform-origin: 50%;
  border: 2px solid transparent;
  border-radius: 50%;
  content: "";
  animation: linear c-inline-spinner-kf 900ms infinite;
  position: relative;
  vertical-align: inherit;
  line-height: inherit;
}
.c-inline-spinner {
  top: 3px;
  margin: 0 3px;
}
.c-inline-spinner:before {
  position: absolute;
  left: -2px;
  top: -2px;
  border-style: solid;
}

.c-inline-spinner-white {
  border-color: #ffffff #ffffff transparent transparent;
}
.c-inline-spinner-white:before {
  border-color: #ffffff #ffffff transparent transparent;
}

.c-inline-spinner-purple {
  border-color: #C152F5 #C152F5 transparent transparent;
}
.c-inline-spinner-purple:before {
  border-color: #C152F5 #C152F5 transparent transparent;
}


.c-inline-spinner-large,
.c-inline-spinner-large:before {
  display: inline-block;
  width: 50px;
  height: 50px;
  transform-origin: 50%;
  border: 2px solid transparent;
  border-radius: 50%;
  content: "";
  animation: linear c-inline-spinner-kf 900ms infinite;
  position: relative;
  vertical-align: inherit;
  line-height: inherit;
}
.c-inline-spinner-large {
  top: 3px;
  margin: 0 3px;
}
.c-inline-spinner-large:before {
  position: absolute;
  left: -2px;
  top: -2px;
  border-style: solid;
}



:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);

  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;

  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;

  /*Used only for colored theme*/
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;

  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;

  /* Used when no type is provided
  // toast("**hello**") */
  --toastify-color-progress-light: linear-gradient(
    to right,
    #4cd964,
    #5ac8fa,
    #007aff,
    #34aadc,
    #5856d6,
    #ff2d55
  );
  /* Used when no type is provided*/
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}


.anon-card {
    max-width: 1200px;
    margin: 40px auto;
    display: flex;
    justify-content: center;
    /*align-items: center;*/
    background-color: #2e3844;
    border-radius: 10px;
    box-shadow: 0px 5px 24px 5px #00000042;
  }
  .anon-card > .anon-img-container {
    width: 500px;
    /*height: 500px;*/
    flex-basis: 500px;
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 0;

    position: relative;
    text-align: center;
    color: #682b85;
  }
  .anon-img-container img {
    width: 95%;
    /*height: 480px;*/
    /*border-radius: 30px 0px 0px 30px;*/
    border-radius: 10px;

  }

  /* Bottom left text */
.imgOverlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  height: 100%;

  display: flex;
  justify-content: center;
  flex-direction: column;
}

.overlayText {
  padding-top: 20px;
  font-size: 54px;
  font-weight: 900;
}

.pulsate {
  opacity: 0.5;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  
}
@keyframes pulsate {
  0% { 
      opacity: 0.5;
  }
  50% { 
      opacity: 1.0;
  }
  100% { 
      opacity: 0.5;
  }
}


  .anon-stats {
    width: 100%;
    padding: 15px 15px;
    display: flex;
    flex-direction: column;
  }

  .text-center {
    text-align: center;
  }
  .text-right {
    text-align: right;
  }
  .pointer {
    cursor: pointer;
  }
  .actions {
    text-align: center;
    margin: 15px;
  }
  .credits {
    text-align: center;
    margin: 15px;
  }

  @media only screen and (max-width: 1280px) {
    .anon-card {
      text-align: center;
      flex-direction: column;
      margin: 50px 0;
      height: 1020px;
    }
    .anon-card > .anon-img-container {
      width: 100%;
      height: 100%;
      flex-basis: initial;
      flex-grow: initial;
      flex-shrink: initial;
    }
    .anon-stats {
      padding: 5px 0;
    }
  }
  @media only screen and (max-width: 640px) {
    .anon-card img {
      height: inherit;
      width: auto;
      border-radius: 10px;
    }
  }
  @media only screen and (min-width: 640px) and (max-width: 1280px) {
    .anon-card > .anon-img-container {
      width: initial;
      height: initial;
      flex-basis: initial;
      flex-grow: initial;
      flex-shrink: initial;
    }
    .anon-card img {
      height: 500px;
      width: 500px;
      border-radius: 10px;
      margin-top: 30px;
    }
  }

  table {
    border-collapse: collapse;
    margin-bottom: 10px;
    width: 100%;
  }
  
  td, th {
    padding: 6px;
    text-align: left;
  }
  
  th {
    border-bottom: 1px solid #dbdbdb;
  }
  
  tbody tr:nth-child(even) {
    background-color: #161f27;
  }


  .decrypt-spinner {
    /*
    position: absolute;
    left: 50%;
    top: 50%;*/
    height:100px;
    width:100px;
    margin:0px auto;
    animation: rotation .6s infinite linear;
    border-left:10px solid #2d103b77;
    border-right:10px solid #2d103b77;
    border-bottom:10px solid #561f729a;
    border-top:10px solid #C152F5;
    border-radius:100%;
  }
  @keyframes rotation {
    from {transform: rotate(0deg);}
    to {transform: rotate(359deg);}
  }
  /* fuck all of this */


/* Customize the label (the container) */
.styles_tileContainer__1fGaW {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

  /* Hide the browser's default checkbox */
  /* .tileContainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  } */
  
  /* Create a custom checkbox */
  .styles_checkmark__10fAv {
    /* position: absolute;
    top: 0;
    left: 0; */
    height: 25px;
    width: 25px;
    background-color: #eee;
  }
  
  /* On mouse-over, add a grey background color
  .tileContainer:hover input ~ .checkmark {
    background-color: #ccc;
  }
  
  /* When the checkbox is checked, add a blue background */
  .styles_tileContainer__1fGaW input:checked ~ .styles_checkmark__10fAv {
    background-color: #C152F5;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  /* .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  } */
  
  /* Show the checkmark when checked */
  /* .tileContainer input:checked ~ .checkmark:after {
    display: block;
  } */
  
  /* Style the checkmark/indicator */
  /* .tileContainer .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }  */

.styles_gtIssued__1dK6U {
  border: 1px solid red;
  border-radius: 20px;
}

.styles_imgContainer__2Uo-y {
  position: relative;
  text-align: right;
}

.styles_topRight__2qNj1 {
  position: absolute;
  top: -10px;
  right: 16px;
}
.tooltip-inner {
    background-color: #fff;
    color: #000;
    /* border: 1px solid #062e56; */
    margin-right: 15px;
  }

  .tooltip::after {
    position: absolute;
    left: 89%;
    top: 35%;
    content: '';
    width: 15px;
    height: 15px;
    background: #fff;
    transform: rotate(45deg);
}
.factory-img-container img {
    width: 75%;
    /*height: 480px;*/
    /*border-radius: 30px 0px 0px 30px;*/
    border-radius: 10px;
    padding-bottom: 10px;

  }

  @media only screen and (max-width: 1280px) {
    .factory-img-container img {
      width: inherit;
      height: 100%
    }
  }

  @media only screen and (max-width: 640px) {
    .factory-img-container img {
      width: 100%;
      height: 100%
    }
  }
.mintModal {
    background-color: #2e3844;
}

.modalSize {
    width: 60%;
    max-width: none !important;
    height: 80%;
    max-height: none !important;
}

.modal-90w {
    width: 90%;
    max-width: none !important;
  }
.society-card {
  max-width: 800px;
  width: 100%;
  background-color: #2e3844;
  border-radius: 10px;
  box-shadow: 0px 5px 24px 5px #00000042;
  padding: 40px;
  margin: 20px auto;
}

.society-left-col {
  padding-right: 30px;
  padding-top: 20px;
}

.society-right-col {
  padding-top: 20px;
}

.discord-label {
  width: 100%;
}

.steddy-img {
  height: 100%;
  width: 100%;
}

.discord-box {
  width: 100%;
  padding-left: 15px;
  margin-top: 10px;
}

.discord-input-container {
  margin-top: 35px;
  margin-bottom: 25px;
}

.society-card .keplrButton {
  position: relative;
  width: 100%;
}

.s-spinner {
  position: absolute;
  top: 15px;
  margin-left: 10px;
}

