.society-card {
  max-width: 800px;
  width: 100%;
  background-color: #2e3844;
  border-radius: 10px;
  box-shadow: 0px 5px 24px 5px #00000042;
  padding: 40px;
  margin: 20px auto;
}

.society-left-col {
  padding-right: 30px;
  padding-top: 20px;
}

.society-right-col {
  padding-top: 20px;
}

.discord-label {
  width: 100%;
}

.steddy-img {
  height: 100%;
  width: 100%;
}

.discord-box {
  width: 100%;
  padding-left: 15px;
  margin-top: 10px;
}

.discord-input-container {
  margin-top: 35px;
  margin-bottom: 25px;
}

.society-card .keplrButton {
  position: relative;
  width: 100%;
}

.s-spinner {
  position: absolute;
  top: 15px;
  margin-left: 10px;
}
